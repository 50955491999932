// i18next-extract-mark-ns-start capabilities-emissions-loc

import { LINKS, EMISSIONS_NAV, CAPABILITIES_PRODUCTS_RINGPAK_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesLOCEmissionsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ringpak = getImage(data.ringpak.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							Oil is vital to engine performance for cooling and lubrication, however burning and leaking oil adds to emissions and consumption of carbon-based fluids.
							Understanding how design parameters affect oil consumption is key to reducing usage whilst maximising component life and durability.
							The priority of reducing oil consumption has never been greater as manufacturers need to meet emission standards,
							such as Euro 7 for vehicles and Euro 5+ for motorcycles as well as developing combustion engines for non-carbon fuels including hydrogen
							where oil will still be used as the lubricant.
						</p>
						<p>
							Oil consumption maps are typically obtained from steady-state and transient Lubricant oil Consumption (LOC) measurements.
							It is estimated that between 40% and 80% of engine oil consumption is from the piston ring pack,
							making this area the primary focus to reduce emissions from oil consumption.
							Simulation software enables this analysis to be performed virtually, with more parameters assessed and understood prior to physical testing.
						</p>
					</Trans>

					{ringpak && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ringpak}
								alt="RINGPAK"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							RINGPAK, is an advanced 2-D simulation package which enable users to predict and optimise ring pack dynamics, lubrication,
							gas flow and oil consumption using both steady state and transient models.
							Recent improvements in transient modelling allow for fuel-shut off simulation under different transient load/speed drive cycles
							which is not captured by steady state models.
							This new analytical method uses transient 1-D engine performance simulations with finite-element thermal and structural
							calculations of the in-cylinder to provide boundary conditions for each transient, reducing expensive physical engine development
							testing and improving design trouble shooting at an early stage.
						</p>
						<p>
							Motorcycles provide a specific application for RINGPAK. Unlike cars the oil lubricates the transmission and clutch as well as the engine.
							Higher phosphorus motorcycle oil must be used to ensure transmission durability, which when lost into the exhaust system damages the catalyst and increases emissions.
							Therefore, predicting oil consumption, has a direct effect on reducing exhaust emissions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CuttingEmissions")}
						className="widget__sibling__pages">
						<AssetList>
							{EMISSIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_RINGPAK_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilitiesLOCEmissionsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-emissions-loc", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/emissions/Realis-Simulation_1325_motorcycleExhaust_cropped.jpg" }) {
		...imageBreaker
	}
	ringpak: file(relativePath: { eq: "content/products/ringpak/rs_ringpak.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "LOC" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
